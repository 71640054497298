import React, { useContext, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Container } from '@material-ui/core';
import { Card, Grid } from '@mui/material';
import RegistrationStepper from './RegistrationStepper';
import ProgressBar from '../../ProgressBar';
import { ProfileStrengthContext } from '../../../context/ProfileStrengthContext';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { CandidateContext } from '../../../context/CandidateContext';
import GlobalLoader from '../../GlobalLoader';

function UpdateStepper() {
  const { strength } = useContext(ProfileStrengthContext)
  const { profileStrength } = useContext(CandidateContext)
  const { redirectionLink } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if(profileStrength >= 50) {
      history.push(redirectionLink)
    }
  }, [history, profileStrength, redirectionLink])

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingX: { xs: '20px !important', md: '40px !important' }, paddingTop: {xs: '40px !important'}, paddingBottom: {xs: '60px !important'} }}>
      <Container>
        {!profileStrength ? 
        <GlobalLoader height="60vh" />
        :
        <Card sx={{
          display: 'flex',
          width: '100%',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2), 0px 16px 32px rgba(0, 0, 0, 0.4)',
          borderRadius: '22px',
          justifyContent: 'center',
          flexDirection: 'column',
          overflow: "unset"
        }}
        >

          <Grid item md={12} xs={12}>
            <Box m={6}>
              <Typography align='center' variant='h6' sx={{ fontWeight: 600 }}>To apply for your dream job, please complete your profile</Typography>
            </Box>
          </Grid>

          <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <ProgressBar profile_percent={strength} />
          </Grid>

          <Grid item md={12} xs={12}>
            <RegistrationStepper />
          </Grid>
        </Card>
        }
      </Container>
    </Box >
  )
}

export default UpdateStepper;