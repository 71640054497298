import { Box, Grid, IconButton, Stack, Typography, Pagination } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import DrawerComponent from "../../components/DrawerComponent";
import TuneSharpIcon from '@mui/icons-material/TuneSharp';
import Select from 'react-select'

import { JobSearchContext } from "../../context/JobSearchContext";
import NoResultsFound from "../../components/NoResultsFound";
import CandidateContextProvider, { CandidateContext } from "../../context/CandidateContext";
import { UserContext } from "../../context/UserContext";
import JobsCard from "../../components/CandidateComponents/CandidateAppliedJobs/JobsCard";
import GlobalLoader from "../../components/GlobalLoader";
import FilterComponent from "./FilterComponent";
import useIntersectionObserver from "../../CustomHooks/IntersectionObserverHook";
import SponsoredJobCard from "../../components/JobCard/SponsoredJobCard";

function GridIcon({ grid }) {
    const strokeColor = grid ? "#0275D8" : "#808080";

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" stroke={strokeColor} />
            <g clipPath="url(#clip0_1185_485)">
                <path d="M17 12H13C12.4477 12 12 12.4477 12 13V17C12 17.5523 12.4477 18 13 18H17C17.5523 18 18 17.5523 18 17V13C18 12.4477 17.5523 12 17 12Z" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27 12H23C22.4477 12 22 12.4477 22 13V17C22 17.5523 22.4477 18 23 18H27C27.5523 18 28 17.5523 28 17V13C28 12.4477 27.5523 12 27 12Z" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17 22H13C12.4477 22 12 22.4477 12 23V27C12 27.5523 12.4477 28 13 28H17C17.5523 28 18 27.5523 18 27V23C18 22.4477 17.5523 22 17 22Z" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27 22H23C22.4477 22 22 22.4477 22 23V27C22 27.5523 22.4477 28 23 28H27C27.5523 28 28 27.5523 28 27V23C28 22.4477 27.5523 22 27 22Z" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1185_485">
                    <rect width="24" height="24" fill="white" transform="translate(8 8)" />
                </clipPath>
            </defs>
        </svg>
    );
}

function ListIcon({ grid }) {
    const strokeColor = grid ? "#808080" : "#0275D8";

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.06" y="0.975586" width="40" height="39.0244" rx="4" fill="#F6F8FF" />
            <g clipPath="url(#clip0_1185_487)">
                <path d="M26 12.6826H14C12.8954 12.6826 12 13.5562 12 14.6338V16.5851C12 17.6627 12.8954 18.5363 14 18.5363H26C27.1046 18.5363 28 17.6627 28 16.5851V14.6338C28 13.5562 27.1046 12.6826 26 12.6826Z" fill="#F6F8FF" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M26 22.439H14C12.8954 22.439 12 23.3126 12 24.3902V26.3414C12 27.419 12.8954 28.2926 14 28.2926H26C27.1046 28.2926 28 27.419 28 26.3414V24.3902C28 23.3126 27.1046 22.439 26 22.439Z" fill="#F6F8FF" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <rect x="0.5" y="0.5" width="39" height="38.0244" rx="5.5" stroke={strokeColor} />
            <defs>
                <clipPath id="clip0_1185_487">
                    <rect width="24" height="23.4146" fill="white" transform="translate(8 8.78027)" />
                </clipPath>
            </defs>
        </svg>
    );
}

function JobDetailsHeader({ jobInformation, jobSearchData, setJobSearchData, jobIndexRef, setJobResults, setPageNumber, setJobIndexRef }) {

    const totalJobs = Array.isArray(jobInformation?.jobs) && jobInformation?.jobs?.length > 0
        ? jobInformation.jobs.filter((item) => (
            item?._source.is_cxo === true && (
                item?._source.negotiable === false ||
                (item?._source.minimum_salary !== null && item?._source.minimum_salary !== undefined && item?._source.minimum_salary < 100000)
            )
        ))
        : [];

    const [gridView, setGridView] = useState(true);
    const { candidateData, settingCandidateData, appliedJobsIds } = useContext(CandidateContext);
    const { isLoggedin, userData } = useContext(UserContext);

    useEffect(() => {
        setGridView(true)
    }, [window.innerWidth])

    useEffect(() => {
        const scrollData = jobIndexRef
        window.scrollTo({
            top: scrollData, // Scroll to the specified position
            behavior: 'smooth' // Smooth scrolling
        });
    }, [])

    const sortByOptions = [
        {
            label: 'Latest Jobs',
            value: 'latest_job'
        }, {
            label: 'Salary High to Low',
            value: 'salary_high_to_low'
        }, {
            label: 'Salary Low to High',
            value: 'salary_low_to_high'
        }
    ]

    return (
        <React.Fragment>
            <Grid container
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-between",
                    alignItems: { xs: "flex-start", sm: "center" },
                    boxShadow: '0px 4px 4px 0px #00000040',
                    background: "#ffffff",
                    borderRadius: "4px",
                    p: 2,
                    my: 3
                }}
            >
                <Grid item sx={{ mb: { xs: 2, sm: 0 } }}>
                    <Typography
                        variant="h3"
                        sx={{
                            fontWeight: 700,
                            fontSize: '20px',
                            '@media (max-width: 600px)': {
                                fontSize: '18px',
                            },
                            textAlign: { xs: 'center', md: 'left' }
                        }}
                    >
                        We found <span style={{ color: "#1976D2" }}>({totalJobs?.length}) </span>jobs
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack
                        direction={'row'}
                        spacing={2}
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 500,
                                fontSize: '14px',
                                '@media (max-width: 600px)': {
                                    fontSize: '12px'
                                },
                            }}
                        >
                            Sort By:
                        </Typography>
                        <Select
                            isClearable
                            placeholder='Select'
                            styles={{ control: base => ({ ...base, height: '40px', '@media (max-width: 600px)': { width: '150px' } }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                            className='react-select-container'
                            classNamePrefix='react-select'
                            options={sortByOptions}
                            onChange={(e) => [setJobResults([]), setPageNumber(1), setJobSearchData({ ...jobSearchData, 'sort_by': e?.value })]}
                        />

                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                            <IconButton
                                aria-label="grid-view"
                                onClick={() => setGridView(true)}
                                sx={{
                                    borderRadius: '10px'
                                }}
                            >
                                <GridIcon grid={gridView} />
                            </IconButton>

                            <IconButton
                                aria-label="list-view"
                                sx={{ marginLeft: '0px !important', borderRadius: '10px' }}
                                onClick={() => setGridView(false)}
                            >
                                <ListIcon grid={gridView} />
                            </IconButton>
                        </Box>

                        <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, justifyContent: 'flex-start' }}>
                            <DrawerComponent filterComponent={<FilterComponent />} />
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
            {
                jobInformation?.isLoading ?
                    <GlobalLoader height="50vh" /> :
                    <Grid container spacing={2}>
                        {totalJobs?.length === 0 ? (
                            <NoResultsFound />
                        ) : isLoggedin && candidateData.id > 0 && candidateData?.is_cxo_eligible === undefined ? (
                            <Grid item xs={12}>
                                <GlobalLoader height="50vh" />
                            </Grid>
                        ) : (
                            totalJobs?.map((item, index) => {

                                const sanitizedData = {
                                    job_post: {
                                        post_id: item._source.post_id,
                                        job_title: item._source.job_title,
                                        minimum_salary: item._source.minimum_salary,
                                        maximum_salary: item._source.maximum_salary,
                                        job_deadline: item._source.job_deadline,
                                        is_cxo: item._source.is_cxo,

                                        job_type: {
                                            name: item._source.job_type.name
                                        },
                                        qualification: {
                                            name: item._source.qualification.name
                                        },
                                        district: {
                                            name: item._source.district.name
                                        },
                                        salary_currency: {
                                            name: item._source.salary_currency.name
                                        },
                                        salary_type: {
                                            type: item._source.salary_type.name
                                        }
                                    },
                                    employer: {
                                        employer_name: item._source.employer.employer_name,
                                        profile_pic_base64: item._source.employer.profile_pic_base64,
                                    },
                                    author_id: item._source.employer.user_id,
                                    job_benefits: item._source.job_benefits,
                                    sponsored_image: item._source.sponsor_image
                                }

                                return (
                                    // dont show the item which salary is negotiable and min salary is less then 100k (
                                    <Grid key={index} item xs={12} md={gridView ? 6 : 12} id={item?.post_id} onClick={() => setJobIndexRef(item?.post_id)}>
                                        {
                                            item?._source.sponsored ?
                                                <SponsoredJobCard item={sanitizedData} index={index} grid={gridView} />
                                                :
                                                <JobsCard
                                                    item={sanitizedData}
                                                    index={index}
                                                    grid={gridView}
                                                    candidateData={candidateData}
                                                    appliedJobsIds={appliedJobsIds}
                                                    settingCandidateData={settingCandidateData}
                                                    userData={userData}
                                                />
                                        }
                                    </Grid>
                                )
                            }
                            )
                        )}
                    </Grid>
            }
        </React.Fragment>
    )
}

export default function SearchCXOJobs() {

    const { jobs, isLoading, jobResults, setJobResults, jobSearchData, pageNumber, setPageNumber, handleSearch, setJobSearchData, jobIndexRef, setJobIndexRef } = useContext(JobSearchContext)
    const [isCxo, setIsCxo] = useState(false); // Track cxo state separately

    const [jobInformation, setJobInformation] = useState({
        jobs: [],
        pagination_info: '',
        isLoading: false,
        current_page: ''
    })

    const [paginationLoader, setPaginationLoader] = useState(false);

    const jobListRef = useRef(null);
    const isInViewport = useIntersectionObserver(jobListRef, { threshold: 0.25 }, !isLoading);

    useEffect(() => {
        setJobSearchData({ is_cxo: true });
        setIsCxo(true); // Set isCxo to true to prevent further updates

    }, [isCxo]); // Run this effect when isCxo changes

    // // Effect to update cxo job info
    useEffect(() => {
        setJobInformation({
            jobs: jobResults,
            pagination_info: jobs?.hits?.total?.value || {}, // Fallback to an empty object if undefined
            isLoading: isLoading,
            current_page: pageNumber
        });
        setPaginationLoader(false);
    }, [jobResults]);

    useEffect(() => {
        if (isInViewport && jobInformation.current_page < jobInformation.pagination_info / 30) {
            setPaginationLoader(true);
            setPageNumber(jobInformation?.current_page + 1)
        }
    }, [isInViewport])

    return (
        <Box
            sx={{
                backgroundColor: '#f9f9f9'
            }}
        >
            <Box>
                <Grid container spacing={0}>
                    <Grid item md={3}>
                        <Box sx={{
                            display: { xs: 'none', md: 'block' },
                            maxHeight: 'calc(100vh - 100px)',
                            position: 'sticky',
                            top: 100,
                            zIndex: 1000,
                            overflowY: 'auto',
                            boxShadow: '-1px 3px 5px 2px rgb(0 0 0 / 18%)'
                        }}
                        >
                            <FilterComponent />
                        </Box>
                    </Grid>
                    <Grid item md={9} sx={{ width: '100%' }}>
                        <Box
                            sx={{
                                margin: 4,
                                '@media (max-width: 600px)': {
                                    margin: 2,
                                },
                                minHeight: "100dvh"
                            }}
                        >
                            <CandidateContextProvider>
                                <JobDetailsHeader
                                    jobInformation={jobInformation}
                                    jobSearchData={jobSearchData}
                                    setJobSearchData={setJobSearchData}
                                    jobIndexRef={jobIndexRef}
                                    setJobIndexRef={setJobIndexRef}
                                    setJobResults={setJobResults}
                                    setPageNumber={setPageNumber}
                                />
                            </CandidateContextProvider>

                            <Box ref={jobListRef}></Box>
                            {
                                paginationLoader ?
                                    <GlobalLoader />
                                    :
                                    null
                            }

                            {/* Old Pagination component */}
                            {/* {paginateInfo?.total > 0 && (
                                <Box m={{ xs: 1, md: 3 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Pagination
                                        page={currentPage}
                                        count={paginateInfo?.last_page}
                                        color="primary"
                                        shape="rounded"
                                        boundaryCount={1}
                                        onChange={changePage}
                                        sx={{
                                            '& .MuiPaginationItem-root': {
                                                fontSize: '0.75rem',
                                            },
                                            '& .MuiPaginationItem-page.Mui-selected': {
                                                fontSize: '0.75rem',
                                            },
                                            '@media (max-width: 600px)': {
                                                '& .MuiPaginationItem-root': {
                                                    fontSize: '0.65rem',
                                                },
                                                '& .MuiPaginationItem-page.Mui-selected': {
                                                    fontSize: '0.65rem',
                                                },
                                                '& .MuiPaginationItem-ellipsis': {
                                                    display: 'none',
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            )} */}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}